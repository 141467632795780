import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import queryString from "query-string";
import withRouter from "@tvg/utils/withCustomRouter";
import { v4 as uuid } from "uuid";
import DOMPurify from "dompurify";
import loadable from "@loadable/component";
import {
  bindAll,
  get,
  attempt,
  isString,
  isEqual,
  isEmpty,
  noop,
  startCase
} from "lodash";
import axios from "axios";
import { isBrazeInitialized, brazeContentCardsRefresh } from "@tvg/braze";

import { RegionType } from "@tvg/ts-types/Geolocation";
import geoServices from "@tvg/api/geo";
import tvgConf from "@tvg/conf";

import mediator from "@tvg/mediator";
import Poller from "@tvg/poller";
import buildRaceUrl from "@tvg/formatter/url";
import TvgGeolocation from "@tvg/geolocation";
import LoginController from "@tvg/login-controller";
import {
  handleIOSLocation,
  setGeoLocationData
} from "@tvg/login-controller/src/utils/userLocation";
import Messenger from "@tvg/messenger/src/component";
import isMobile from "@tvg/utils/mobileUtils";
import { setRFRCookie } from "@tvg/utils/PromoUtils";
import { getRegionByType } from "@tvg/utils/geoUtils";
import {
  setRaceSelectorModalStateAction,
  fixWagerSelector
} from "@tvg/shared-actions/ProgramActions";
import { closeHandicappingStoreModal } from "@tvg/shared-actions/HandicappingStore";
import { showVideo } from "@tvg/program-page/actions/video";
import {
  setBlockedCountry,
  rgTimeoutExclusionSplash,
  setLocationLoading,
  setAndroidBlockedState,
  clearSplashErrors
} from "@tvg/location-splash/actions";
import { getCleanedUrl } from "@tvg/utils/xss";
import { createConversation, events as AlchemerEvents } from "@urp/alchemer";
import MtpStatus from "@tvg/mtp-update/src";
import {
  iOSTouchIdToggle,
  iOSTouchIdToggleChange
} from "@tvg/mobile-account/src/actions";
import calculateFeatureOverride from "@tvg/utils/featuresUtils";
import BrazeInAppMessages from "@tvg/atomic-ui/_atom/BrazeInAppMessages";
import SplashNotification from "@tvg/atomic-ui/_templates/SplashNotification";
import {
  getBetCancelIsLoadingBetCancelRequest,
  getTodayActiveCounter,
  getTodaySettledCounter,
  getActiveWageredAmountCounter,
  getSettledWageredAmountCounter,
  getMyBetsCounterToggle,
  getTodayFutureCounter,
  getFutureWageredAmountCounter,
  getBetSocialShareIsOpen
} from "@tvg/sh-lib-my-bets/redux/selectors";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { isAfter } from "date-fns";
import {
  getEmailUnsubscribeToggle,
  getNotificationPreferencesToggle
} from "@tvg/sh-lib-preferences/redux/selectors";
import { amplitudeInit, JURISDICTION_LOCAL_STORAGE_ITEM } from "@urp/amplitude";
import requester from "@tvg/api/requester";
import {
  getAccountNumber,
  getEmail,
  getResidenceState
} from "@urp/store-selectors";
import {
  iOSInitResponseAction,
  iOSTouchIDResponseAction,
  iOSTouchIDErrorAction,
  iOSNotificationsStateAction
} from "../actions/ios";
import {
  failFeaturesRequest,
  failMessagesRequest,
  successFeaturesRequest,
  successMessagesRequest,
  successSeoMessagesRequest
} from "../actions/capi";
import { setUserAccountNumber } from "../actions/user";
import {
  closePasswordRecoveryModal,
  closePromosModal,
  closeEmailReferralModal,
  closeDepositsModal,
  openDepositsModal,
  fixDepositModalForSightlinePrompt,
  quickDepositToFullDepositRedirect
} from "../actions/modals";
import { initialState } from "../reducers/App/appReducer";
import Main from "./Main";
import Footer from "./components/Footer";
import Header from "./components/Header";
import {
  DepositsModal,
  MyBetsModal,
  PasswordRecoveryModal,
  PromosModal,
  EmailReferralModal,
  FeatureTogglesModal,
  HandicapStoreModal,
  ContentCardsModal,
  BetCancelModal,
  ApproxPayoutHelpModal,
  BetSocialShareModal
} from "./components/Modals";

import perf from "./utils/perf";
import { interceptor } from "./utils/routerInterceptor";
import sendGTMPageView, { sendATTracking } from "./utils/gtm";
import isSafari from "./utils/isSafari";
import pageConfig from "./utils/config";
import {
  trackingInit,
  getAmplitudeGlobalProperties,
  amplitudeSessionStart,
  amplitudeSessionEnd,
  MEP_PAGE_CONFIG
} from "./utils/amplitude";
import styles from "./style/styles.css";
import getUserPromos from "../services/optedInPromos";

const Onboardings = loadable(
  /* webpackChunkName: 'Onboardings' */ () => import("@tvg/onboardings")
);
const SplashLoader = loadable(
  /* webpackChunkName: 'SplashLoader' */ () => import("@tvg/splash-loader")
);
const RevalidateModalComponent = loadable(
  /* webpackChunkName: 'RevalidateLocation' */ () =>
    import("@tvg/revalidate-location")
);
const ResponsibleGamingModal = loadable(
  () =>
    import(
      /* webpackChunkName: 'ResponsibleGamingModal' */ "@tvg/responsible-gaming-modal"
    )
);
const QuickDeposit = loadable(
  () =>
    import(
      /* webpackChunkName: 'QuickDeposit' */ "@tvg/wallet/src/components/QuickDeposit"
    )
);

const LocationSplash = loadable(() => import("@tvg/location-splash"));

const PromosPoller = new Poller();
const BrazePoller = new Poller();
const RefreshPoller = new Poller();
const Geolocation = new TvgGeolocation();

export const startUserPromosPoller = (dispatch, accountNumber) => {
  PromosPoller.start(() => getUserPromos(dispatch, accountNumber), 30000);
};

const manageEquibaseId = (toAdd) => {
  if (toAdd) {
    if (!attempt(() => window.localStorage.getItem("equibaseId"))) {
      attempt(() => window.localStorage.setItem("equibaseId", uuid()));
    }
  } else if (attempt(() => window.localStorage.getItem("equibaseId"))) {
    attempt(() => window.localStorage.removeItem("equibaseId"));
  }
};

export const getHandicapStoreUrl = (url, isLogged) => {
  // if user was already logged in and didnt have equibaseId in localStorage
  const equibaseId = attempt(() => window.localStorage.getItem("equibaseId"));

  if (!equibaseId && isLogged) {
    manageEquibaseId(true);
  }

  const deviceParameter = "device=mobile";
  const uuidParameter = `uuid=${attempt(() =>
    window.localStorage.getItem("equibaseId")
  )}`;
  const urlParameters = `${deviceParameter}&${uuidParameter}`;

  if (!url) {
    return `https://${tvgConf().config().domain.equibase}?${urlParameters}`;
  }

  return `${url}${url.includes("?") ? "&" : "?"}${urlParameters}`;
};

const reloadApp = (history, isSplash = true) => {
  // reload current location with force fetch from the server
  if (isSplash) {
    history.push("?splash=1");
  }
  window.location.reload(true);
};

const rcnErrorHack = () => {
  let errorEvent = get(window, "NanoEventHandlers.onError");

  if (errorEvent) {
    window.NanoEventHandlers.onError = (event) => {
      if (get(event, "data.code") !== 1007) {
        errorEvent(event);
      }
    };
  }

  const rcnScript = document.getElementById("rcnScript");

  if (rcnScript) {
    rcnScript.onload = () => {
      errorEvent = get(window, "NanoEventHandlers.onError");
      if (errorEvent) {
        window.NanoEventHandlers.onError = (event) => {
          if (get(event, "data.code") !== 1007) {
            errorEvent(event);
          }
        };
      }
    };
  }
};

const getRfrValue = () =>
  typeof window !== "undefined" && !!window.localStorage.getItem("rfr")
    ? window.localStorage.getItem("rfr")
    : null;

const hasRaceChanged = (newSearch = "", oldSearch = "") => {
  if (newSearch.indexOf("race=") > -1 && oldSearch.indexOf("race=") > -1) {
    const params = queryString.parse(newSearch.toLowerCase());
    const oldParams = queryString.parse(oldSearch.toLowerCase());

    return params.race !== oldParams.race;
  }
  return false;
};

export class Root extends Component {
  constructor(props) {
    super(props);

    bindAll(this, [
      "getRaceDataFromUrl",
      "handleIOSEvents",
      "handleAppEvents",
      "handleMessageEvents",
      "openRaceNavigation",
      "showVideoHeader"
    ]);

    this.tvg = tvgConf();

    if (typeof window !== "undefined") {
      setRFRCookie(isMobile(this.tvg.product), this.props.location);
      rcnErrorHack();
    }
  }

  conversationCreated = false;

  previousPath = React.createRef();

  amplitudeGlobalPropsRef = React.createRef();

  state = initialState;

  componentDidMount() {
    if (typeof window === "undefined") {
      return;
    }
    this.shouldOpenLoginModal();
    this.handleInvalidSession();

    if (
      typeof window !== "undefined" &&
      !queryString.parse(window.location.search).rfr
    ) {
      window.localStorage.removeItem("rfr");
    }

    this.updateLayout();

    // Calculates feature overrides and throttles with the features toggles from store
    if (!isEmpty(this.props.features)) {
      const featureToggles = {};
      let featureOverrides = this.getFeaturesOverrides() || {};
      const hasFeaturesOverrides = !!this.getFeaturesOverrides();

      this.props.features.forEach((toggle) => {
        let { enabled } = toggle;
        if (typeof window !== "undefined") {
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );
          if (get(featureOverrides, toggle.name)) {
            enabled = featureOverrides[toggle.name].enabled;
          }
        }

        featureToggles[toggle.name] = enabled;
      });

      if (!isEmpty(featureOverrides)) {
        this.setFeaturesOverrides(featureOverrides);
      }
      this.props.dispatch(successFeaturesRequest(featureToggles));
    } else {
      this.getFeatures();
    }

    this.getMessages();
    this.getNotificationsStatus();

    // Pulls content for matched paths to populate the TrackPage component
    const path = get(this.props, "location.pathname");

    if (path && /^\/racetracks\/[^/]+\/?$/.test(path)) {
      this.getTrackHubMessages(path);
    }

    this.handleIOSEvents();
    this.handleAppEvents();
    this.handleMessageEvents();

    // tries to get the user id (accountNumber) from localStorage and dispatch it to user data
    // this is useful because every component initialized by tvg-mobile will know if the user is logged, plus it's
    // account number beforehand
    attempt(() => {
      if (window.sessionStorage.getItem("userId")) {
        this.props.dispatch(
          setUserAccountNumber(window.sessionStorage.getItem("userId"))
        );
      }
    });

    // checks if user has account suspended based on session storage IS_SUSPENDED key
    // this ensures the moneypak RG limits on the DMA
    // the user must
    attempt(() => {
      if (
        this.props.isRGAvailableOnDMA &&
        window.sessionStorage.getItem("IS_SUSPENDED")
      ) {
        mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT" });
        window.sessionStorage.removeItem("IS_SUSPENDED");
      }
    });

    // this will poll the /refresh endpoint to check if the app should be reloaded
    // if not ,this will cause a hard refresh on the next page transition
    const self = this;
    RefreshPoller.start(
      () =>
        axios
          .get(`${this.tvg.config("service.capi")}/devices/mobile`)
          .then(({ data }) => {
            if (data && data.refreshTimestamp) {
              const newRefreshTimestamp = new Date(
                data.refreshTimestamp
              ).getTime();
              if (
                newRefreshTimestamp &&
                newRefreshTimestamp > self.refreshTimestamp
              ) {
                self.hardReload = true;
              }
              self.refreshTimestamp = newRefreshTimestamp;
            }
          }),
      60000
    );

    if (!get(this.props, "user.isLogged")) {
      // send initial page view
      sendGTMPageView(this.props);
    }

    // TODO: verify if this and next one can be moved to login controller
    mediator.base.subscribe("TVG_LOGIN:RG_EXCEPTION", (data) => {
      const { type, endDate } = get(data, "payload", {});
      this.props.dispatch(rgTimeoutExclusionSplash(type, endDate));
    });
    mediator.base.subscribe("OPEN_REGISTRATION", () => {
      let params = {
        fullPage: this.props.enableDepositOnSinglePage ? "true" : "false",
        appVersion: this.props.appVersion
      };

      if (getRfrValue()) {
        params = { ...params, rfr: getRfrValue() };
      }

      window.location.href = this.tvg.buildUrl({
        app: "rma",
        wrapper: true,
        callback: true,
        params
      });
    });

    // register start timer when not running in ios app
    if (!isMobile(this.tvg.product)) {
      perf.register(
        "app_start",
        get(window, "performance.timing.navigationStart")
      );
      perf.measure("app_start");
    }
    if (this.props.enableAmplitude) {
      this.amplitudeGlobalPropsRef.current = getAmplitudeGlobalProperties(
        this.props
      );
      amplitudeInit({
        getGlobalProperties: () => this.amplitudeGlobalPropsRef.current,
        pagesConfig: MEP_PAGE_CONFIG,
        extra: {
          prevPath: this.previousPath
        }
      });
      trackingInit();
    }

    mediator.base.subscribe("TVG_LOGIN:LOGIN_SUCCESS", ({ userData }) => {
      if (userData) {
        const { accountNumber, currentLocationByState } = userData;
        createConversation(userData);
        AlchemerEvents.login();
        amplitudeSessionStart({
          accountNumber,
          homeState: currentLocationByState
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (typeof window === "undefined") {
      return;
    }

    const raceChanged = hasRaceChanged(
      get(nextProps, "location.search"),
      get(this.props, "location.search")
    );

    const pathChange =
      get(nextProps, "location.pathname") !==
      get(this.props, "location.pathname");

    const hashChanged =
      get(nextProps, "location.hash") !== get(this.props, "location.hash");

    const isLogoutRedirect = get(nextProps, "location.state.isLogout");

    // route change
    if (pathChange || hashChanged || raceChanged) {
      perf.register("page_render");
      perf.register("page_load");
      this.setState({
        prevPath: this.props.location.pathname + this.props.location.search
      });

      if (
        typeof window !== "undefined" &&
        !queryString.parse(window.location.search).rfr
      ) {
        window.localStorage.removeItem("rfr");
      }
      // send gtm page view when location changes
      if (!isLogoutRedirect) {
        sendGTMPageView(nextProps, pathChange || raceChanged);
      }
    }

    if (
      isLogoutRedirect &&
      this.props.user.isLogged !== nextProps.user.isLogged
    ) {
      // send gtm page view when user triggers logout button on more page
      sendGTMPageView(nextProps);
      amplitudeSessionEnd();
    }

    if (
      this.props.user.isLogged === nextProps.user.isLogged &&
      nextProps.user.accountNumber !== "" &&
      nextProps.user.homeState !== "" &&
      this.props.user.homeState === ""
    ) {
      // first load of component with user data
      // send gtm page view
      sendGTMPageView(nextProps);
      amplitudeSessionStart(nextProps.user);
    }

    if (
      this.props.user.isLogged !== nextProps.user.isLogged ||
      this.props.user.balance !== nextProps.user.balance ||
      JSON.stringify(this.props.location) !== JSON.stringify(nextProps.location)
    ) {
      const jurisdiction = attempt(() =>
        localStorage.getItem(JURISDICTION_LOCAL_STORAGE_ITEM)
      );
      this.amplitudeGlobalPropsRef.current = getAmplitudeGlobalProperties(
        nextProps,
        jurisdiction && typeof jurisdiction === "string" ? jurisdiction : ""
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.prevPath !== this.state.prevPath)
      this.previousPath.current = nextState.prevPath;

    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.raceDate &&
      prevProps.raceDate &&
      this.props.raceDate !== prevProps.raceDate
    ) {
      reloadApp(this.props.history, false);
    }

    // TODO: verify if this still needed with loginV2
    if (document.body && this.props.location !== prevProps.location) {
      document.body.classList.toggle("modal", false);
    }

    this.updateLayout();

    interceptor(
      prevProps.location,
      this.props.location,
      this.props.dispatch,
      this.props.history,
      this.props.user.isLogged,
      this.props.modal.deposits.redirectedFromQuickToFull,
      this.props.myBetsStandaloneToggle,
      this.props.loginModal.loginOpen,
      this.props.enablePawsQuickDeposits
    );

    // Verify if user session has expired

    if (
      typeof window !== "undefined" &&
      get(prevProps, "location.pathname") !==
        get(this.props, "location.pathname")
    ) {
      window.scrollTo(0, 0);

      // detect when we must hard reload because new build is available
      if (this.hardReload) {
        reloadApp(this.props.history, true);
      }
    }
    if (
      this.props.useAndroidGpsAllowedStates &&
      this.tvg.product === "androidwrapper"
    ) {
      this.forceBlockedStateAndroid();
    }

    if (!this.conversationCreated && this.props.user.isLogged) {
      this.conversationCreated = true;
      createConversation(this.props.user);
    }
  }

  // SMALL WORKAROUND TO PREVENT A LOT OF CHANGES ON MODALS
  shouldOpenLoginModal = () => {
    if (typeof window !== "undefined") {
      const shouldOpen = sessionStorage.getItem("open_login_modal");

      if (shouldOpen === "true") {
        mediator.base.dispatch({ type: "TVG_LOGIN:OPEN_LOGIN_MODAL" });
        sessionStorage.removeItem("open_login_modal");
      }
    }
  };

  validateSessionExpired = (error) => {
    if (
      this.props.user.isLogged &&
      error?.response?.status === 401 &&
      error?.response?.data &&
      error?.response?.data?.code === 100001
    ) {
      mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT" });
      this.props.history.push("/");

      if (typeof window !== "undefined") {
        sessionStorage.setItem("open_login_modal", "true");
        window.location.reload();
      }

      return Promise.reject(error);
    }
    return Promise.reject(error);
  };

  // Verify if user session has expired
  handleInvalidSession = () => {
    requester().interceptors.response.use(
      (res) => res,
      (error) => this.validateSessionExpired(error)
    );
    axios.interceptors.response.use(
      (res) => res,
      (error) => this.validateSessionExpired(error)
    );
  };

  forceBlockedStateAndroid = () => {
    if (
      this.props.user.isLogged &&
      !isEmpty(this.props.androidGpsAllowedStates) &&
      !this.props.androidGpsAllowedStates.includes(
        get(this.props.geolocation, "state")
      )
    ) {
      this.props.dispatch(setAndroidBlockedState());
    }

    if (
      (!this.props.user.isLogged ||
        this.props.androidGpsAllowedStates.includes(
          get(this.props.geolocation, "state")
        )) &&
      this.props.splashError === "ANDROID_BLOCKED_STATE"
    ) {
      this.props.dispatch(clearSplashErrors());
    }
  };

  getFeaturesOverrides = () =>
    attempt(
      () => JSON.parse(window.localStorage.getItem("featureOverrides")),
      false
    );

  setFeaturesOverrides = (features) =>
    attempt(() =>
      window.localStorage.setItem("featureOverrides", JSON.stringify(features))
    );

  getFeatures = () =>
    this.tvg
      .getFeatures()
      .then((response) => {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        if (response && Array.isArray(response.featureToggles)) {
          response.featureToggles.forEach((toggle) => {
            let { enabled } = toggle;
            featureOverrides = calculateFeatureOverride(
              hasFeaturesOverrides,
              featureOverrides,
              toggle
            );
            if (get(featureOverrides, toggle.name)) {
              enabled = featureOverrides[toggle.name].enabled;
            }

            featureToggles[toggle.name] = enabled;
          });
        }

        if (!isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }
        this.props.dispatch(successFeaturesRequest(featureToggles));
      })
      .catch((err) => this.props.dispatch(failFeaturesRequest(err)));

  getMessages = () => {
    const messageNamespaces = [
      "MobileWebApp",
      "Login",
      "Global",
      "InformationalPages",
      "BetTicket",
      "WagerRewards",
      "Homepage",
      "RedirectEngine",
      "ResponsibleGaming",
      "DepositLimits",
      "Race",
      "Registration",
      "PasswordRecovery"
    ];

    return this.tvg
      .getMessages(messageNamespaces)
      .then((response) => this.props.dispatch(successMessagesRequest(response)))
      .catch((err) => this.props.dispatch(failMessagesRequest(err)));
  };

  getTrackHubMessages = (path) => {
    const nameSpace = ["SeoData"];
    const trackAbbr = path.slice(1).split("/")[1].toUpperCase();
    const trackHubName = `raceTrackHub${trackAbbr}`;

    return this.tvg
      .getMessages(nameSpace)
      .then((response) => {
        const seoContent = typeof response === "object" ? response : {};
        const trackContent = get(seoContent, `${trackHubName}`);

        if (trackContent) {
          this.props.dispatch(
            successSeoMessagesRequest(trackHubName, trackContent)
          );
        }
      })
      .catch((err) => this.props.dispatch(failMessagesRequest(err)));
  };

  getNotificationsStatus = () => {
    if (isMobile(this.tvg.product)) {
      mediator.ios.subscribe("PUSH_NOTIFICATION_STATE", (data) => {
        if (!data.error) {
          this.props.dispatch(
            iOSNotificationsStateAction(
              get(data, "payload.pushNotificationEnabled")
            )
          );
        }
      });

      mediator.ios.dispatch({
        type: "ASK_PUSH_NOTIFICATION_STATE",
        payload: { silent: true }
      });
    }
  };

  getPageNameFromUrl = () => {
    const pathName = get(this.props, "location.pathname", "");
    return pathName.includes("/racetracks/")
      ? "Program Page"
      : startCase(pathName.split("/").pop()) || "Home";
  };

  getRaceDataFromUrl(nextProps) {
    const pathname = get(nextProps, "location.pathname");
    let newState = { trackCode: "", raceNumber: "" };

    if (isString(pathname) && pathname.includes("racetracks")) {
      const pathnameSplitted = pathname.split("/");

      attempt(() => {
        const trackCode =
          typeof pathnameSplitted[2] !== "undefined" ? pathnameSplitted[2] : "";
        const urlParams = queryString.parse(get(nextProps, "location.search"));

        const oldRaceNumber =
          typeof pathnameSplitted[4] !== "undefined" &&
          pathnameSplitted[4].includes("-")
            ? pathnameSplitted[4].split("-")[1]
            : "";

        const raceNumber =
          urlParams && urlParams.race ? urlParams.race : oldRaceNumber;

        newState = {
          trackCode,
          raceNumber
        };
        this.setState(newState);
      });
    } else {
      this.setState(newState);
    }

    return newState;
  }

  /** 🛑 STOP! Hammer time! 🔨 Na, na, na... can't touch this! 👷
   * The overflow on tablet in safari needs to be hidden because the program page and
   * wager rewards opt-in layout is fixed due to issues with scrolling and contents being under the footer
   */ isFixedLayout = () => {
    let livePageStyling = this.props.location.pathname.indexOf("/live") === 0;
    let racetracksPageStyling =
      this.props.location.pathname.indexOf("/racetracks") === 0;
    if (this.props.enableSeoContentLivePage) {
      livePageStyling =
        this.props.location.pathname.indexOf("/live") === 0 &&
        this.props.isLogged &&
        this.props.returningUser;
    }
    if (this.props.enableSeoTrackInfoPage) {
      racetracksPageStyling =
        this.props.location.pathname.indexOf("/racetracks") === 0 &&
        this.props.location.search.indexOf("?race=") === 0;
    }
    return (
      (isSafari(true) &&
        this.tvg.device === "tablet" &&
        this.tvg.product === "touch3" &&
        (racetracksPageStyling || livePageStyling)) ||
      (this.props.location.pathname.indexOf("/wager-rewards") === 0 &&
        !this.props.user.isLogged)
    );
  };

  updateLayout = () => {
    if (document.body && document.documentElement && this.isFixedLayout()) {
      document.body.classList.toggle("no-overflow", true);
      document.documentElement.classList.toggle("no-overflow", true);
    } else if (document.body && document.documentElement) {
      document.body.classList.toggle("no-overflow", false);
      document.documentElement.classList.toggle("no-overflow", false);
    }
  };

  loginCallback;

  rgModalCallback;

  rgModalCallbackSecondary;

  tvg;

  refreshTimestamp;

  hardReload = false;

  startContentCardsPoller(brazerTimer = this.props.brazePoller) {
    BrazePoller.start(() => {
      if (isBrazeInitialized()) brazeContentCardsRefresh();
    }, brazerTimer * 1000);
  }

  handleAppEvents() {
    mediator.base.subscribe("GET_RG_MODAL_CALLBACK", (data) => {
      this.rgModalCallback = get(data, "payload.callback", noop);
    });
    mediator.base.subscribe("GET_RG_MODAL_CALLBACK_SECONDARY", (data) => {
      this.rgModalCallbackSecondary = get(data, "payload.callback", noop);
    });
    mediator.base.subscribe("RG_MODAL_SUBMIT", () => {
      if (typeof this.rgModalCallback === "function") {
        this.rgModalCallback();
      }
    });
    mediator.base.subscribe("RG_MODAL_CANCEL", () => {
      if (typeof this.rgModalCallbackSecondary === "function") {
        this.rgModalCallbackSecondary();
      }
    });
    mediator.base.subscribe("RG_MODAL_CLEAR_CALLBACKS", () => {
      this.rgModalCallback = noop;
      this.rgModalCallbackSecondary = noop;
    });
  }

  checkCountryAndroid = (data) => {
    if (this.tvg.product === "androidwrapper") {
      geoServices
        .getRegion(data)
        .then((response) => get(response, "data"))
        .then((regionData) => {
          const region = getRegionByType(
            get(regionData, "regions", []),
            RegionType.STATE
          );
          if (region) {
            this.props.dispatch(setBlockedCountry(region.id === 0));
          }
          this.props.dispatch(setLocationLoading(false));
        })
        .catch(() => null);
    }
  };

  handleIOSEvents() {
    if (isMobile(this.tvg.product)) {
      // Send event to iOS app when app is initialized
      mediator.ios.dispatch({
        type: "@@INIT"
      });
      // Subscribe to iOS init response
      mediator.ios.subscribe("INIT_RESPONSE", (data) => {
        this.props.dispatch(iOSInitResponseAction(get(data, "payload")));

        if (get(data, "payload.location")) {
          this.checkCountryAndroid(get(data, "payload.location"));

          setGeoLocationData(data.payload.location, this.props.dispatch);
          if (this.props.user.isLogged) {
            // validate location on ios
            handleIOSLocation(
              get(data, "payload.location.state"),
              (blacklisted) => {
                if (blacklisted) {
                  mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT" });
                }
              },
              this.props.dispatch
            );
          }
        }
        window.navigator.connection = {
          effectiveType: get(data, "payload.internetConnectionType")
        };
        if (get(data, "payload.launchTimestamp") > 0) {
          perf.register(
            "app_start",
            parseInt(data.payload.launchTimestamp * 1000, 10)
          );
          perf.measure("app_start");
        }

        const pushWooshPAbetsIds = [
          "0AD14-EAF11",
          "AE61D-7A7B3",
          "630E3-2ED26",
          "FAB9C-58FA7"
        ];
        const pushWooshAppId = get(data, "payload.pushwooshAppId");
        if (pushWooshPAbetsIds.includes(pushWooshAppId)) {
          this.setState({ showPAbetsSplashNotification: true });
        }
      });

      mediator.ios.subscribe("LOCATION_RESPONSE", (data) => {
        if (get(data, "payload.location")) {
          const { dispatch, onLocationGet } = this.props;
          setGeoLocationData(data.payload.location, dispatch, onLocationGet);
        }
      });

      mediator.ios.subscribe("SET_CONTENT_CARDS_SUBSCRIPTION_ID", (payload) => {
        if (get(payload, "subscriptionID")) {
          this.setState({ subscription: payload.subscriptionID });
        }
      });

      // Subscribe to iOS ATTracking response
      mediator.ios.subscribe("TRACKING_RESPONSE", (data) => {
        sendATTracking(get(data, "payload.tracking", {}));
      });
      // Subscribe to iOS touch id response
      mediator.ios.subscribe("TOUCH_ID_RESPONSE", (data) => {
        if (get(data, "payload.errorCode") || data.error) {
          mediator.base.dispatch({ type: "TVG_LOGIN:TOUCH_ID_ERROR" });
          this.props.dispatch(iOSTouchIDErrorAction(data.payload));
        } else if (
          get(data, "payload.accountId") &&
          get(data, "payload.token")
        ) {
          this.props.dispatch(iOSTouchIDResponseAction(data.payload));
          const loginData = {
            account: this.props.touchId.accountId,
            pin: this.props.touchId.token,
            stateAbbr: this.props.geolocation.state,
            callback: this.props.loginModal.callback
          };
          mediator.base.dispatch({
            type: "TVG_LOGIN:DO_LOGIN_FORM",
            payload: loginData
          });
        } else if (this.props.touchId.userChangingTouchId) {
          this.props.dispatch(iOSTouchIdToggle(!this.props.touchId.enabled));
          this.props.dispatch(iOSTouchIdToggleChange(false));
        } else {
          this.props.dispatch(
            iOSTouchIdToggle(get(data, "payload.touchIdEnabled"))
          );
        }
      });
      // Subscribe to warm startup event
      mediator.ios.subscribe("HOT_START", (data) => {
        const idleTime = parseInt(
          get(data, "payload.restartTimestamp") * 1000,
          10
        );
        if (idleTime > 0) {
          perf.register("app_start_hot", idleTime);
          perf.measure("app_start_hot");
        }
        // lets check again if the user changed the notification setting for tvg app
        mediator.ios.dispatch({
          type: "ASK_PUSH_NOTIFICATION_STATE",
          payload: { silent: true }
        });
        // recheck the device location
        mediator.ios.dispatch({ type: "ASK_LOCATION" });
      });
      mediator.ios.subscribe("IOS_PUSH_NOTIFICATION", (data) => {
        const fullUrl = get(data, "payload.url", "");
        const url =
          fullUrl.indexOf("://") > -1 ? fullUrl.split("://")[1] : fullUrl;
        const { trackAbbr, trackName, raceNumber } = queryString.parse(url);
        if (trackAbbr && trackName && raceNumber) {
          this.props.history.push(
            buildRaceUrl(trackAbbr, trackName, raceNumber)
          );
        } else if (url) {
          this.props.history.push(url);
        }
      });
      mediator.ios.subscribe("APPLE_PAY_RESPONSE", (data) => {
        const iframe = document.getElementById("iframe");
        if (iframe) {
          iframe.contentWindow.postMessage(data, "*");
        }
      });
    }
  }

  handleMessageEvents() {
    // add message event listener to allow legacy iframes apps like DMA, RMA, PRF and Promos to dispatch actions
    if (typeof window === "object") {
      window.addEventListener("message", (event) => {
        const origin = event.origin || event.originalEvent.origin;

        // stop if message is not from known source...
        if (
          !(this.props.allowedMessageDomains || []).find(
            (domain) => domain === origin
          )
        ) {
          return;
        }

        const path = get(this.props, "location.pathname");
        const parameters = get(this.props, "location.search");
        const currentPath = `${path}${parameters}`;
        const payload = DOMPurify.sanitize(get(event, "data.payload", ""));

        switch (get(event, "data.type")) {
          case "open_login":
            mediator.base.dispatch({ type: "OPEN_LOGIN" });
            break;
          case "open_registration":
            {
              const params = payload;
              let regParams = {
                ...params,
                appVersion: this.props.appVersion
              };

              if (getRfrValue()) {
                regParams = { ...regParams, rfr: getRfrValue() };
              }

              window.location.href = this.tvg.buildUrl({
                app: "rma",
                wrapper: true,
                callback: true,
                params: regParams
              });
            }
            break;
          case "close_promos":
            this.props.history.push(currentPath);
            this.props.dispatch(closePromosModal());
            break;
          case "close_email_referral":
            this.props.history.push(currentPath);
            this.props.dispatch(closeEmailReferralModal());
            break;
          case "close_prf":
            this.props.history.push(currentPath);
            this.props.dispatch(closePasswordRecoveryModal());
            break;
          case "open_deposit":
            this.props.dispatch(
              openDepositsModal({
                title: "Deposit",
                type: "quick-deposit"
              })
            );
            break;
          case "close_deposit":
            this.props.history.push(currentPath);
            this.props.dispatch(closeDepositsModal());
            break;
          case "close_handicapping":
            this.props.history.push(currentPath);
            this.props.dispatch(closeHandicappingStoreModal());
            break;
          case "open_url":
            {
              const url = getCleanedUrl(payload);
              const pattern = /^https?:\/\//;
              const isEmailOrTel = /^(mailto|tel|sms):.+/.test(url);
              const isRelativePath = /^\/.+/.test(url);
              const isFullUrl = pattern.test(url);
              let openExternalApp =
                !isFullUrl && !isRelativePath && !isEmailOrTel
                  ? `http://${url}`
                  : url;
              openExternalApp = isRelativePath
                ? `${window.location.protocol}//${window.location.host}${url}`
                : openExternalApp;
              if (isMobile(this.tvg.product)) {
                mediator.ios.dispatch({
                  type: "OPEN_EXTERNAL_APP",
                  payload: { openExternalApp }
                });
              } else {
                // eslint-disable-next-line
                return window.open(url, "_blank"); // eslint-disable-line
              }
            }
            break;
          case "paypal_redirect":
            {
              const pageUrl = getCleanedUrl(payload);
              document.location.href = pageUrl;
            }
            break;
          case "sightline_redirect":
            {
              const pageUrl = getCleanedUrl(payload);
              this.props.dispatch(fixDepositModalForSightlinePrompt(pageUrl));
            }
            break;
          case "open_page":
            {
              const pageUrl = getCleanedUrl(payload);
              this.props.history.push(pageUrl);
            }
            break;
          case "quick_deposit_redirect_full_deposit":
            this.props.dispatch(quickDepositToFullDepositRedirect());
            break;
          default:
            // eslint-disable-next-line
            return false;
        }
        // eslint-disable-next-line
        return true;
      });
    }
  }

  openRaceNavigation() {
    this.props.dispatch(setRaceSelectorModalStateAction(true));
  }

  showVideoHeader() {
    this.props.dispatch(showVideo());
    if (typeof window !== "undefined") {
      const fixedOffset = (window.innerWidth * 9) / 16;
      this.props.dispatch(
        fixWagerSelector({ isWagerSelectorFixed: true, fixedOffset })
      );
    }
  }

  shouldRenderHeader = (config) => {
    const path = get(this.props, "location.pathname");
    const search = get(this.props, "location.search");

    // server side render of header on program page
    if (
      typeof window === "undefined" &&
      path.includes("racetracks") &&
      path.includes("?race=")
    ) {
      return true;
    }

    // specific configuration for "all" racetracks and program page
    if (path.includes("racetracks") && !search) return false;

    if (!config.isVisible) return false;

    // specific configuration for handicapping including Talent Picks page
    if (path.includes("handicapping/talent-picks")) return true;

    return !this.props.showVideo || get(this.tvg, "device") === "tablet";
  };

  shouldRenderFooter = (config) => {
    if (config && !config.isVisible) return false;

    return this.props.isFooterVisible || get(this.tvg, "device") === "tablet";
  };

  render() {
    const path = get(this.props, "location.pathname");

    const parameters = get(this.props, "location.search");
    const currentPath = `${path}${parameters}`;
    const queryParameters = queryString.parse(parameters);
    const query = get(this.props, "query");
    const isFixed = this.isFixedLayout();
    const { header: pageHeaderCfg, footer: pageFooterCfg } =
      pageConfig.getComponentCfg(path, parameters) || {};
    const HeaderChildComponent = get(pageHeaderCfg, "childComponent");
    const showHeaderChildComponentProp = get(
      pageHeaderCfg,
      "showChildComponentProp"
    );
    const showHeaderChildComponent = showHeaderChildComponentProp
      ? get(this.props, showHeaderChildComponentProp)
      : true;
    const showQuickDeposit = !currentPath.includes("/wallet");

    return (
      <Fragment>
        {this.props.brazeHeaderInAppMessaging && isBrazeInitialized() && (
          <BrazeInAppMessages device={get(this.tvg, "device")} />
        )}
        <Header
          location={this.props.location}
          title={get(pageHeaderCfg, "title")}
          logoMod={get(pageHeaderCfg, "logoMod")}
          isActionToolsShown={get(pageHeaderCfg, "isActionToolsShown")}
          isLogActionsShown={get(pageHeaderCfg, "isLogActionsShown")}
          openRaceNavigation={this.openRaceNavigation}
          currentPath={currentPath}
          prevPath={get(pageHeaderCfg, "prevPath", this.state.prevPath)}
          wagerProfile={this.props.user.wagerProfile}
          trackCode={this.state.trackCode}
          raceNumber={this.state.raceNumber}
          isLogged={this.props.user.isLogged}
          isBalanceShown={this.props.isBalanceShown !== "0"}
          balance={this.props.user.balance}
          isVisible={this.shouldRenderHeader(pageHeaderCfg)}
          isNavigationShown={this.props.isNavigationShown}
          isVideoButtonShown={this.props.showVideoButtonHeader}
          isTitleCenter={get(pageHeaderCfg, "isTitleCenter")}
          videoFeedback={this.props.videoFeedback}
          device={get(this.tvg, "device")}
          onVideoButton={this.showVideoHeader}
          currentRace={this.props.currentRace}
          headerClass={get(pageHeaderCfg, "headerClass")}
          description={get(pageHeaderCfg, "description")}
          isContentCardsInboxShown={
            path.indexOf("/racetracks") !== 0 && path.indexOf("/live") !== 0
          }
          enablePawsQuickDeposits={this.props.modal.enablePawsQuickDeposits}
        >
          {HeaderChildComponent && showHeaderChildComponent && (
            <HeaderChildComponent />
          )}
        </Header>
        <MtpStatus>
          <Main
            key="main"
            prevPath={this.state.prevPath}
            isLogged={this.props.user.isLogged}
            device={get(this.tvg, "device")}
            product={get(this.tvg, "product")}
            isHeaderVisible={this.shouldRenderHeader(pageHeaderCfg)}
            isFooterVisible={this.shouldRenderFooter(pageFooterCfg)}
            isFullScreen={!get(pageFooterCfg, "isVisible", true)}
            toggles={{
              enableSeoTrackInfoPage: this.props.enableSeoTrackInfoPage,
              enablePromosStoryblok: this.props.enablePromosStoryblok,
              wagerRewardsApp: this.props.wagerRewardsApp,
              enableReferFriend: this.props.enableReferFriend,
              usePrefReact: this.props.usePrefReact,
              useEmailUnsubscribe: this.props.useEmailUnsubscribe,
              pawsWalletAvailable: this.props.featureToggles.pawsWalletAvailable
            }}
            isBeta={this.props.isBeta}
            isFixed={isFixed}
          />
        </MtpStatus>
        <Footer
          activePath={currentPath || ""}
          isLogged={this.props.user.isLogged}
          isVisible={this.shouldRenderFooter(pageFooterCfg)}
          device={get(this.tvg, "device")}
          talentPicksPageEnable={this.props.talentPicksPageEnable}
        />
        <MyBetsModal
          isVideoShown={this.props.showVideo}
          isOpen={
            this.props.myBetsStandaloneToggle
              ? this.props.modal.myBetsStandaloneOpen
              : this.props.modal.myBetsOpen
          }
          dispatch={this.props.dispatch}
          history={this.props.history}
          currentPath={currentPath}
          device={get(this.tvg, "device")}
          myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
          isPastPerformanceOpen={
            this.props.modal.myBetsStandalonePastPerformanceOpen
          }
          pastPerformanceTitle={
            this.props.modal.myBetsStandalonePastPerformanceTitle
          }
          selectedTab={this.props.myBetsStandalone.selectedTab}
          selectedSettledTab={this.props.myBetsStandalone.selectedSettledTab}
          loadMoreWagerGroups={this.props.myBetsStandalone.loadMoreWagerGroups}
          activeBetsCounter={this.props.modal.activeBetsCounter}
          settledBetsCounter={this.props.modal.settledBetsCounter}
          futureBetsCounter={this.props.modal.futureBetsCounter}
          activeWageredAmount={this.props.modal.activeWageredAmount}
          settledWageredAmount={this.props.modal.settledWageredAmount}
          myBetsCounterToggle={this.props.modal.myBetsCounterToggle}
          futureWageredAmount={this.props.modal.futureWageredAmount}
        />
        <PromosModal
          isVideoShown={this.props.showVideo}
          isOpen={this.props.modal.promosOpen}
          isLogged={this.props.user.isLogged}
          dispatch={this.props.dispatch}
          history={this.props.history}
          currentPath={currentPath}
          promoPage={get(queryParameters, "promo", "promos")}
          device={get(this.tvg, "device")}
        />
        <EmailReferralModal
          isOpen={get(this.props, "modal.emailReferralOpen", false)}
          dispatch={this.props.dispatch}
          device={get(this.tvg, "device")}
          history={this.props.history}
        />
        <HandicapStoreModal
          isVideoShown={this.props.showVideo}
          isOpen={this.props.modal.handicappingStore.isOpen}
          iFrameUrl={getHandicapStoreUrl(
            this.props.modal.handicappingStore.url,
            this.props.user.isLogged
          )}
          dispatch={this.props.dispatch}
          history={this.props.history}
          currentPath={currentPath}
          device={get(this.tvg, "device")}
          product={get(this.tvg, "product")}
          className={styles.iframeScrollWrapper}
          hasHeader={this.props.modal.handicappingStore.isModalHasHeader}
          errorMessage={get(
            this.props,
            "messages.equibaseErrorLoadingMessage",
            ""
          )}
        />
        <DepositsModal
          isOpen={this.props.modal.deposits.open}
          title={this.props.modal.deposits.title}
          depositType={this.props.modal.deposits.type}
          isLogged={this.props.user.isLogged}
          dispatch={this.props.dispatch}
          history={this.props.history}
          currentPath={currentPath}
          accountNumber={this.props.user.accountNumber}
          queryParameters={queryParameters}
          device={get(this.tvg, "device")}
          product={get(this.tvg, "product")}
          isSightlineRedirectModalOpen={
            this.props.modal.isSightlineRedirectModalOpen
          }
        />
        <PasswordRecoveryModal
          isOpen={this.props.modal.prf.open}
          title={this.props.modal.prf.title}
          recoveryPage={this.props.modal.prf.type}
          isLogged={this.props.user.isLogged}
          dispatch={this.props.dispatch}
          history={this.props.history}
          currentPath={currentPath}
          queryParameters={queryParameters}
          device={get(this.tvg, "device")}
          product={get(this.tvg, "product")}
        />
        <ResponsibleGamingModal
          dispatch={this.props.dispatch}
          history={this.props.history}
          currentPath={currentPath}
          device={get(this.tvg, "device")}
          product={get(this.tvg, "product")}
        />
        <FeatureTogglesModal
          dispatch={this.props.dispatch}
          featureToggles={this.props.featureToggles}
          isOpen={this.props.modal.featureTogglesOpen}
          history={this.props.history}
          currentPath={currentPath}
          device={get(this.tvg, "device")}
        />
        {this.props.modalContentCardsToggle && this.props.user.isLogged && (
          <ContentCardsModal
            isOpen={this.props.modal.contentCardsIsOpen}
            dispatch={this.props.dispatch}
            history={this.props.history}
            brazeContentCards={this.props.brazeContentCards}
            currentPath={currentPath}
            brazeMessages={this.props.brazeMessages}
            contentCardsCustom={this.props.contentCardsCustom}
            isApp={isMobile(tvgConf().product)}
          />
        )}
        {showQuickDeposit && <QuickDeposit />}
        <LocationSplash
          geolocation={Geolocation}
          className={styles.splashScreen}
        />
        <RevalidateModalComponent
          isApp={isMobile(tvgConf().product)}
          liveChatUrl={this.props.globalSupportLink}
          device={get(this.tvg, "device")}
        />
        <Messenger />
        {this.props.enableOnboardings && (
          <Onboardings
            history={this.props.history}
            location={this.props.location}
            device={get(this.tvg, "device")}
          />
        )}
        {this.props.betCancelModalToggle && (
          <BetCancelModal
            isOpen={get(this.props, "modal.betCancelModalOpen")}
            dispatch={this.props.dispatch}
            history={this.props.history}
            currentPath={currentPath}
            device={get(this.tvg, "device")}
            isLoading={this.props.isLoadingBetCancelRequest}
          />
        )}

        {this.props.betSocialShareModalToggle && (
          <BetSocialShareModal
            isOpen={get(this.props, "modal.betSocialShareModalOpen")}
            dispatch={this.props.dispatch}
            history={this.props.history}
            currentPath={currentPath}
            device={get(this.tvg, "device")}
            isLoading={false}
          />
        )}
        <ApproxPayoutHelpModal
          isOpen={get(this.props, "modal.approxPayoutModalOpen")}
          dispatch={this.props.dispatch}
          device={get(this.tvg, "device")}
        />
        {this.props.showLoadingSplash &&
          typeof window === "undefined" &&
          query.splash === "1" && (
            <SplashLoader
              device={get(this.tvg, "device")}
              brand={get(this.tvg, "brand")}
              messages={this.props.messages}
            />
          )}
        <LoginController history={this.props.history} />
        {this.props.showPAbetsSplashNotification &&
          this.state.showPAbetsSplashNotification &&
          isAfter(
            new Date(),
            new Date(this.props.pabetsSplashNotification.showAfterDate)
          ) && (
            <SplashNotification
              device={get(this.tvg, "device")}
              title={get(this.props.pabetsSplashNotification, "title", "")}
              description={get(
                this.props.pabetsSplashNotification,
                "description",
                ""
              )}
              buttonText={get(
                this.props.pabetsSplashNotification,
                "buttonText",
                ""
              )}
              buttonAction={() => {
                mediator.ios.dispatch({
                  type: "OPEN_EXTERNAL_APP",
                  payload: {
                    openExternalApp: "tvg://"
                  }
                });
              }}
              qaLabel="splash-notification-button"
            />
          )}
      </Fragment>
    );
  }
}

export default withRouter(
  connect((store) => ({
    user: {
      firstName: get(store, "userData.user.firstName"),
      lastName: get(store, "userData.user.lastName"),
      isLogged: store.userData.logged,
      returningUser: get(store, "userData.returningUser", false),
      balance: store.userData.balance,
      balancePoller: store.userData.balancePoller,
      wagerProfile: get(store, "userData.user.profile"),
      accountNumber: getAccountNumber(store),
      userName: get(store, "userData.user.userName"),
      homeState: getResidenceState(store),
      emailAddress: getEmail(store),
      country: get(store, "userData.user.homeAddress.country"),
      phone: get(store, "userData.user.primaryPhone")
    },
    androidGpsAllowedStates: get(
      store,
      "capi.messages.androidGpsAllowedStates",
      []
    ),
    appVersion: get(store, "ios.init.appVersion", ""),
    splashError: store.locationSplash.error,
    isLocationSplashOpen: store.locationSplash.isOpen,
    isLocationRequired: store.locationSplash.isLocationRequired,
    hasPromoBadge: get(store, "capi.featureToggles.promosDisplayBadge", false),
    talentPicksPageEnable: get(
      store,
      "capi.featureToggles.talentPicksPageEnable",
      false
    ),
    videoFeedback: get(store, "RaceProgram.videoFeedback"),
    isNavigationShown: get(
      store,
      "RaceProgram.showHeaderRaceNavigation",
      false
    ),
    showVideoButtonHeader: get(
      store,
      "RaceProgram.showVideoButtonHeader",
      false
    ),
    isBalanceShown: get(store, "userData.preferences.balance_is_shown", "0"),
    isFooterVisible:
      !get(store, "RaceProgram.startBetting", false) ||
      !get(store, "RaceProgram.betType", false),
    messages: store.capi.messages,
    featureToggles: get(store, "capi.featureToggles"),
    features: get(store, "capi.features"),
    geolocation: store.geolocation,
    showVideo: store.RaceProgramVideo.show,
    loginModal: get(store, "loginModal"),
    modal: {
      ...store.modal,
      isQuickDepositOpen:
        get(store, "paws.depositFundsModal.isModalOpen", false) &&
        get(store, "paws.depositFundsModal.isQuickDeposit", false),
      myBetsOpen: store.myBets.isOpen,
      myBetsStandaloneOpen: store.myBetsStandalone.isOpen,
      betCancelModalOpen: get(
        store,
        "myBetsStandalone.betCancelModal.isOpen",
        false
      ),
      betSocialShareModalOpen: getBetSocialShareIsOpen(store),
      contentCardsIsOpen: get(store, "modal.contentCardsOpen", false),
      approxPayoutModalOpen: get(
        store,
        "myBetsStandalone.approxPayoutModal.isOpen",
        false
      ),
      myBetsCounterToggle: getMyBetsCounterToggle(store),
      activeBetsCounter: getTodayActiveCounter(store),
      settledBetsCounter: getTodaySettledCounter(store),
      futureBetsCounter: getTodayFutureCounter(store),
      activeWageredAmount: getActiveWageredAmountCounter(store),
      settledWageredAmount: getSettledWageredAmountCounter(store),
      futureWageredAmount: getFutureWageredAmountCounter(store),
      myBetsStandalonePastPerformanceOpen: get(
        store,
        "myBetsStandalone.pastPerformance.isOpen",
        false
      ),
      myBetsStandalonePastPerformanceTitle: get(
        store,
        "myBetsStandalone.pastPerformance.modalTitle",
        false
      ),
      availablePaymentMethodsInfos: parseCapiMessage(
        store,
        "capi.messages.availablePaymentMethodsInfos",
        {}
      ),
      enablePawsQuickDeposits: get(
        store,
        "capi.featureToggles.pawsQuickDeposit",
        false
      )
    },
    currentRace: store.RaceProgram.currentRace,
    deviceManufacturer: get(store, "ios.init.deviceManufacturer", ""),
    deviceModel: get(store, "ios.init.deviceModel", ""),
    showDownloadAccountTransactions: get(
      store,
      "accountTransactions.showDownloadTransactions",
      false
    ),
    enableDepositOnSinglePage: get(
      store,
      "capi.featureToggles.enableDepositOnSinglePage",
      false
    ),
    hasPromosOptedIn: get(
      store,
      "capi.featureToggles.promosDisplayOptedIn",
      false
    ),
    touchId: {
      initEnabled: store.ios.init.touchIdEnabled,
      enabled: store.ios.touchId.touchIdEnabled,
      accountId: store.ios.touchId.accountId,
      userChangingTouchId: store.ios.userChangingTouchId,
      token: store.ios.touchId.token
    },
    onLocationGet: store.locationSplash.onLocationGet,
    startBetting: get(store, "RaceProgram.startBetting", false),
    enableOnboardings: get(
      store,
      "capi.featureToggles.enableOnboardings",
      false
    ),
    showLoadingSplash: get(
      store,
      "capi.featureToggles.showLoadingSplash",
      true
    ),
    wagerRewardsApp: get(store, "capi.featureToggles.wagerRewardsApp", false),
    enableSeoTrackInfoPage: get(
      store,
      "capi.featureToggles.enableSeoTrackInfoPage",
      false
    ),
    enableSeoContentLivePage: get(
      store,
      "capi.featureToggles.enableSeoContentLivePage",
      false
    ),
    geopacketUsage: get(store, "capi.featureToggles.geopacketUsage", false),
    isBeta: store.app.isBeta,
    enablePromosStoryblok: get(
      store,
      "capi.featureToggles.enablePromosStoryblok",
      false
    ),
    isRGAvailableOnDMA: get(
      store,
      "capi.featureToggles.isRGAvailableOnDMA",
      false
    ),
    brazePoller: get(store, "brazeData.brazePoller", 60),
    brazeMessages: get(store, "capi.messages.brazeMessages"),
    brazeContentCards: get(store, "brazeData.brazeContentCards"),
    brazeHeaderInAppMessaging: get(
      store,
      "capi.featureToggles.brazeHeaderInAppMessaging",
      false
    ),
    modalContentCardsToggle: get(
      store,
      "capi.featureToggles.headerContentCardsInbox"
    ),
    contentCardsCustom: get(store, "capi.featureToggles.contentCardsCustom"),
    enableReferFriend: get(
      store,
      "capi.featureToggles.enableReferFriendMicroApp",
      false
    ),
    myBetsStandaloneToggle: get(
      store,
      "capi.featureToggles.myBetsStandalone",
      false
    ),
    betCancelModalToggle: get(
      store,
      "capi.featureToggles.betCancelModalFlow",
      false
    ),
    betSocialShareModalToggle: get(
      store,
      "capi.featureToggles.betSocialShareModalFlow",
      false
    ),
    isLoadingBetCancelRequest: getBetCancelIsLoadingBetCancelRequest(store),
    pabetsSplashNotification: parseCapiMessage(
      store,
      "capi.messages.pabetsToTVGNotification",
      {}
    ),
    showPAbetsSplashNotification: get(
      store,
      "capi.featureToggles.showPAbetsToTVGNotification",
      false
    ),
    myBetsStandalone: {
      selectedTab: get(store, "myBetsStandalone.selectedTab", "ACTIVE"),
      selectedSettledTab: get(
        store,
        "myBetsStandalone.selectedSettledTab",
        "TODAY"
      )
    },
    useAndroidGpsAllowedStates: get(
      store,
      "capi.featureToggles.useAndroidGpsAllowedStates",
      false
    ),
    globalSupportLink: get(
      store,
      "capi.messages.globalSupportLink",
      "https://support.tvg.com"
    ),
    usePrefReact: get(store, "capi.featureToggles.usePrefReact", false),
    enableNotificationsTab: getNotificationPreferencesToggle(store),
    useEmailUnsubscribe: getEmailUnsubscribeToggle(store),
    enableAmplitude: get(store, "capi.featureToggles.useAmplitude", false),
    raceDate: get(store, "mtpStatus.raceDate"),
    allowedMessageDomains: parseCapiMessage(
      store,
      "capi.messages.allowedMessageDomains",
      []
    )
  }))(Root)
);
